import React from 'react';
import { IconButton, InputAdornment, FormControl, FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import OperatorSelect from 'src/components/common/OperatorSelect';
import utils from 'src/utils/utils';

import ValidityAdornment from './ValidityAdornment';

const DateBoundMassActionField = ({
  classes,
  fieldConfig,
  onChange,
  removeLine,
  removable,
  onOperatorChange,
  validity,
  isWritable,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.massAction.filterLabel.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect
          fieldConfig={fieldConfig}
          onChange={onOperatorChange}
          disabled={!isWritable}
        />
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <DatePicker
          disabled={!isWritable}
          autoOk
          variant="inline"
          style={{
            fontSize: '0.875rem',
            flex: '1',
          }}
          format="d MMM yyyy"
          value={
            (fieldConfig.value &&
              fieldConfig.value.values &&
              fieldConfig.value.values.length &&
              fieldConfig.value.values[0]) ||
            null
          }
          onChange={value => {
            const val = [
              value,
              (fieldConfig.value &&
                fieldConfig.value.values &&
                fieldConfig.value.values.length > 1 &&
                fieldConfig.value.values[1]) ||
                null,
            ];
            onChange(fieldConfig, val);
          }}
        />
        <InputAdornment position="start">
          {utils.getLang('smartmessaging.inputs.AND')}
        </InputAdornment>
        <DatePicker
          disabled={!isWritable}
          autoOk
          variant="inline"
          style={{
            fontSize: '0.875rem',
            flex: '1',
          }}
          format="d MMM yyyy"
          value={
            (fieldConfig.value &&
              fieldConfig.value.values &&
              fieldConfig.value.values.length &&
              fieldConfig.value.values[1]) ||
            null
          }
          onChange={value => {
            const val = [
              (fieldConfig.value &&
                fieldConfig.value.values &&
                fieldConfig.value.values.length > 1 &&
                fieldConfig.value.values[0]) ||
                null,
              value,
            ];
            onChange(fieldConfig, val);
          }}
        />
        {isWritable && removable && (
          <InputAdornment position="start">
            <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)} size="large">
              <DeleteIcon />
            </IconButton>
          </InputAdornment>
        )}
      </div>
    </div>
  </FormControl>
);

DateBoundMassActionField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default DateBoundMassActionField;
