/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { TextField, Tooltip, IconButton, MenuItem } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { DatePicker } from '@mui/x-date-pickers';
import utils from 'src/utils/utils';

// TODO deprecated keyPress
const TextFilterInput = ({ value, onChange, endAdornment, onEnter, ref }) => (
  <div>
    <TextField
      inputProps={{ ref, style: { background: '#ffffff' } }}
      InputProps={{
        endAdornment,
        disableUnderline: true,
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') onEnter();
      }}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);

TextFilterInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  ref: PropTypes.func,
  endAdornment: PropTypes.any.isRequired,
};

TextFilterInput.defaultProps = {
  value: '',
  ref: null,
};

const useStyles = makeStyles()({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    padding: '0px 8px',
  },
  input: {
    textAlign: 'left',
    padding: '6px 24px 6px 4px',
  },
});

const SelectFilterInput = ({ options, onChange }) => {
  const { classes } = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      <TextField
        select
        style={{ flex: 1 }}
        onChange={e => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        value=""
        SelectProps={{
          MenuProps: {
            MenuListProps: { style: { padding: 0, margin: 0 } },
            PopoverClasses: {
              paper: classes.popover,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },

            marginThreshold: 0,
          },
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
        }}
      >
        {options.map(o => (
          <MenuItem dense key={o.label} value={o.value} className={classes.menuItem}>
            {o.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

SelectFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

function DateFilterInput({ value, onChange, endAdornment, onEnter }) {
  return (
    <div style={{ display: 'flex' }}>
      <DatePicker
        inputProps={{ style: { background: '#ffffff' } }}
        autoOk
        format="dd/MM/yyyy"
        placeholder={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.from')}
        value={value[0] || null}
        variant="inline"
        onChange={v => {
          onChange(v, 'start');
        }}
        InputProps={{
          disableUnderline: true,
          onKeyPress: e => {
            if (e.key === 'Enter') onEnter();
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ margin: '0 1px' }} />
      <DatePicker
        inputProps={{ style: { background: '#ffffff' } }}
        autoOk
        format="dd/MM/yyyy"
        placeholder={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.to')}
        value={value[1] || null}
        variant="inline"
        onChange={v => {
          onChange(v, 'end');
        }}
        InputProps={{
          endAdornment,
          disableUnderline: true,
          onKeyPress: e => {
            if (e.key === 'Enter') onEnter();
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

DateFilterInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  endAdornment: PropTypes.any.isRequired,
};

DateFilterInput.defaultProps = {
  value: [],
};

const AddFilterBtn = ({ addFilter, disabled }) => (
  <Tooltip title={utils.getLang('smartmessaging.tooltip.addFilter')}>
    <span>
      <IconButton
        disabled={disabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          addFilter();
        }}
        edge="end"
        size="large"
      >
        <DoneIcon style={{ fill: '#b6b6b6' }} />
      </IconButton>
    </span>
  </Tooltip>
);

AddFilterBtn.propTypes = {
  addFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AddFilterBtn.defaultProps = {
  disabled: false,
};

function FilterInput({ addFilter, column, setInputRef }) {
  const [value, setValue] = useState('');
  const { filterConfig } = column;
  function isValid() {
    return filterConfig.inputConfig.isValid ? filterConfig.inputConfig.isValid(value) : true;
  }
  switch (filterConfig.inputConfig.type) {
    case 'TEXT':
      return (
        <TextFilterInput
          setInputRef={setInputRef}
          onEnter={() => {
            if (isValid()) {
              addFilter({ ...filterConfig.model, value }, true);
              setValue('');
            }
          }}
          endAdornment={
            <AddFilterBtn
              disabled={!isValid()}
              addFilter={() => {
                addFilter({ ...filterConfig.model, value }, true);
                setValue('');
              }}
            />
          }
          onChange={pValue => setValue(pValue)}
          value={value}
        />
      );
    case 'DATE':
      return (
        <DateFilterInput
          onEnter={() => {
            if (isValid()) {
              addFilter({ ...filterConfig.model, value }, true);
              setValue([]);
            }
          }}
          endAdornment={
            <AddFilterBtn
              disabled={!isValid()}
              addFilter={() => {
                addFilter({ ...filterConfig.model, value }, true);
                setValue([]);
              }}
            />
          }
          onChange={(pValue, bound) => {
            switch (bound) {
              case 'start':
                setValue([pValue, (value.length === 2 && value[1]) || '']);
                break;
              case 'end':
                setValue([value[0] || '', pValue]);
                break;
              default:
                break;
            }
          }}
          value={(value.length && value) || []}
        />
      );
    case 'SELECT':
      return (
        <SelectFilterInput
          onChange={pValue => {
            addFilter({ ...column.filterConfig.model, value: pValue }, true);
          }}
          options={column.filterConfig.inputConfig.getOptions()}
        />
      );
    default:
      return <TextField disabled />;
  }
}

FilterInput.propTypes = {
  isValid: PropTypes.func,
  addFilter: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  setInputRef: PropTypes.func,
};

FilterInput.defaultProps = { isValid: () => true, setInputRef: () => {} };

export default FilterInput;
